import axios from "./api";

export const loginApi = (props) => axios.post("/auth/login", props);

export const createAccountsApi = (id) =>
  axios.get(`/website/listing/get-listing/638f93a2dd0e1ed6c0c8731f`);

export const getListing = (id) =>
  axios.get(`/website/listing/get-listing/638f93a2dd0e1ed6c0c8731f`);

export const getListingByPageId = (page_id) =>
  axios.get(`/website/listing/get-listing-page-id/${page_id}`);

export const getMultiProperty = (ids) =>
  axios.post(`/website/listing/get-multi-property`, { ids });

export const getMultiDocument = (ids) =>
  axios.post(`/website/listing/get-multi-documents`, {
    ids,
  });

export const downloadConfDoc = (id) =>
  axios.post(`/document/download-conf-doc`, {
    id,
  });

export const logDownloads = (document_id, downloaded_by) =>
  axios.post(`/website/listing/log-document-download`, {
    document_id,
    downloaded_by,
  });

export const userApi = (token) => axios.post("/auth/user", { token });

export const registerApi = (props) =>
  axios.post("/auth/register", { ...props });

export const forgotPasswordApi = (email) =>
  axios.post("/auth/forgot-password", { email });

export const validateOTPApi = (props) =>
  axios.post("/auth/validate-otp", props);

export const resendOTPApi = (otp_id) =>
  axios.post("/auth/resend-otp", { otp_id });

export const resetPasswordApi = (props) =>
  axios.post("/auth/reset-password", props);

export const createPasswordApi = (props) =>
  axios.post("/auth/create-password", props);

export const authTestApi = (token) =>
  axios.get("/admin/test/sample_response", {
    headers: { Authorization: `Bearer ${token}` },
  });

export const createInterest = (props) =>
  axios.post("/interest/create-interest", props);

// Investing Accounts
export const createInvestingAccounts = (props) =>
  axios.post("/accounts/create-investing-accounts", props);

export const getInvestingAccounts = (id) =>
  axios.get(`/accounts/get-investing-accounts/${id}`);

export const getMultiInvestingAccounts = (ids) =>
  axios.post(`/accounts/get-multi-investing-accounts`, {
    ids,
  });

export const updateInvestingAccountsProfile = (id, props) =>
  axios.post(`/accounts/investing-accounts-details/${id}`, props);

export const getInvestingAccountsList = (
  investor_id,
  list_size,
  count,
  search = ""
) =>
  axios.get(
    `/accounts/investing-accounts-list/${investor_id}/${list_size}/${count}/${search}`
  );

export const deleteInvestingAccounts = (data) =>
  axios.post(`/accounts/delete-investing-accounts`, {
    _id: data,
  });

// Investor Accounts
export const getInvestorAccountsProfile = (token) =>
  axios.post("/accounts/investor-details", { token });

export const updateInvestorAccountsProfile = (id, props) =>
  axios.put(`/investor/update-invertor-details/${id}`, props);

// Change Password

export const changeInvestorPassword = (props) =>
  axios.post("investor/update-invertor-password", props);

// Investor Interest
export const getInvestorInterest = (list_size, count, investor_id, token) =>
  axios.get(
    `/interest/investor-interest-list/${list_size}/${count}/${investor_id}`,
    { token }
  );
