import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { downloadConfDoc } from "../../client/methods";

const DownloadConfDocs = () => {
  const { doc_id } = useParams();

  useEffect(() => {
    download_docs(doc_id);
  }, [doc_id]);

  const download_docs = async (input) => {
    try {
      const result = await downloadConfDoc(input);

      const link = document.createElement("a");
      link.href = result.data.response.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(
        "🚀 ~ file: download.js:16 ~ constdownload_docs= ~ error",
        error
      );
    }
  };

  return <>Validating... Please wait...</>;
};

export default DownloadConfDocs;
