import dayjs from "dayjs";

export const currencyFormat = (value, type = "USD") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: type,
  });
  return formatter.format(parseFloat(value ? value : 0));
};

export const dateFormat = (input, format = "DD MMM YYYY") =>
  dayjs(input).format(format);

export const list_size = 10;

export const crispStyle = {
  container: (prop) => ({
    ...prop,
  }),
  control: (prop, { isFocused, isDisabled }) => ({
    ...prop,
    alignItems: "flex-start",
    borderRadius: "5px",
    minHeight: "35px",
    borderColor: isFocused ? "#86b7fe" : "#ced4da",
    // boxShadow: isFocused ? "0 0 0 0.2rem #44444440" : "0 !important",
    fontColor: "#675d5a",
    "&:hover": {
      // borderColor: "#ced4da",
    },
    "&:focus": {
      // borderColor: "#222",
    },
  }),
  input: (prop) => ({
    ...prop,
    margin: 0,
    padding: "0",
    fontSize: ".9rem",
  }),
  singleValue: (prop) => ({
    ...prop,
    margin: 0,
    padding: 0,
    fontSize: ".9rem",
    color: "#495057",
  }),
  indicatorsContainer: (prop) => ({
    ...prop,
    margin: 0,
    padding: 0,
  }),

  dropdownIndicator: (prop) => ({
    ...prop,
  }),
  indicatorSeparator: (prop) => ({
    ...prop,
    backgroundColor: "#dee2e6",
  }),
  clearIndicator: (prop) => ({
    ...prop,
  }),
  valueContainer: (prop, { isMulti }) => ({
    ...prop,
    minHeight: "33px",
    padding: isMulti ? "0 15px" : "10.2px",
  }),
  option: (prop, { isSelected, isFocused }) => ({
    ...prop,
    padding: "12px",
    fontSize: ".9rem",
    // backgroundColor: isSelected ? "#343a40" : isFocused && "#ced4da",
    // "&:hover": {
    //   backgroundColor: isSelected ? "#343a40" : "#ced4da",
    // },
  }),
  noOptionsMessage: (prop) => ({
    ...prop,
    fontSize: ".9rem",
  }),
  placeholder: (prop) => ({
    ...prop,
    fontSize: ".9rem",
  }),
  menu: (prop) => ({
    ...prop,
    borderRadius: "3px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999, top: base.top - 5 }),
};

export const numberFormatter = (num, digits = 0, currency = "USD") => {
  // console.log("🚀 ~ numberFormatter ~ num:", num);

  // return num?.toFixed(2);

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });
  const symb = formatter.format(1).replace("1.00", "");

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: " Million" },
    { value: 1e9, symbol: " Billion" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return (
    symb +
    (item ? (num / item.value).toFixed(0).replace(rx, "$1") + item.symbol : "0")
  );
};

export const numberFormatterOnly = (value) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var res = formatter.format(parseFloat(value ? value : 0));
  return res.replace(".00", "").replace("$", "");
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password) => {
  const re = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
  const sp_re = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  return re.test(password) || sp_re.test(password);
};

export const investment_next_options = [
  {
    label: "Undecided",
    value: "0",
  },
  {
    label: "$100,000 - $249,999",
    value: "1",
  },
  {
    label: "$250,000 - $449,999",
    value: "2",
  },
  {
    label: "$500,000 - $999,999",
    value: "3",
  },
  {
    label: "$1,000,000+",
    value: "4",
  },
];

export const investment_now_options = [
  {
    label: "Undecided",
    value: "0",
  },
  {
    label: "$100,000 - $249,999",
    value: "1",
  },
  {
    label: "$250,000 - $449,999",
    value: "2",
  },
  {
    label: "$500,000 - $999,999",
    value: "3",
  },
];
