import React from "react";
import Tab from "react-bootstrap/Tab";
import Fade from "react-reveal/Fade";
import Tabs from "react-bootstrap/Tabs";
import Carousel from "react-bootstrap/Carousel";
import { FaCheckCircle } from "react-icons/fa";
import "./style.scss";
import AMGCover from "../../images/amg_cover.jpg";
import oakforest_1 from "../../images/past-project/oakforest-1.jpeg";
import oakforest_2 from "../../images/past-project/oakforest-2.jpeg";
import oakforest_3 from "../../images/past-project/oakforest-3.jpeg";
import oakforest_4 from "../../images/past-project/oakforest-4.jpeg";
import oakforest_5 from "../../images/past-project/oakforest-5.jpeg";

import Forest_Pines_1 from "../../images/past-project/Forest-Pines1.jpeg";
import Forest_Pines_2 from "../../images/past-project/Forest-Pines2.jpeg";
import Forest_Pines_3 from "../../images/past-project/Forest-Pines3.jpeg";
import Forest_Pines_4 from "../../images/past-project/Forest-Pines4.jpeg";
import Forest_Pines_5 from "../../images/past-project/Forest-Pines5.jpeg";

import Parkway_Falls_1 from "../../images/past-project/Parkway-Falls1.jpeg";
import Parkway_Falls_2 from "../../images/past-project/Parkway-Falls2.jpeg";
import Parkway_Falls_3 from "../../images/past-project/Parkway-Falls3.jpeg";
import Parkway_Falls_4 from "../../images/past-project/Parkway-Falls4.jpeg";
import Parkway_Falls_5 from "../../images/past-project/Parkway-Falls5.jpeg";

import west_oaks_1 from "../../images/past-project/west-oaks-1.jpeg";
import west_oaks_2 from "../../images/past-project/west-oaks-2.jpeg";
import west_oaks_3 from "../../images/past-project/west-oaks-3.jpeg";
import west_oaks_4 from "../../images/past-project/west-oaks-4.jpeg";
import west_oaks_5 from "../../images/past-project/west-oaks-5.jpeg";

import bay_vista_1 from "../../images/past-project/Bay-Vista-1.jpeg";
import bay_vista_2 from "../../images/past-project/Bay-Vista-2.jpeg";
import bay_vista_3 from "../../images/past-project/Bay-Vista-3.jpeg";
import bay_vista_4 from "../../images/past-project/Bay-Vista-4.jpeg";
import bay_vista_5 from "../../images/past-project/Bay-Vista-5.jpeg";

const People = ({ leaderRef, groupRef, trackRef }) => {
  return (
    <>
      <div className="sp_sec mb-4" ref={groupRef}>
        {/* <Fade bottom> */}
        <h4>
          <span className="fw-bolder text-app-primary"> The Alamo Group</span>{" "}
          <span className="fw-normal text-app-primary"> (Sponsor)</span>
        </h4>
        {/* </Fade> */}
        <hr />
        {/* <Fade bottom> */}
        <p className="mb-0 text-justify">
          Headquartered in San Antonio with offices in Houston and Dallas, the
          Alamo Group consists of the Sponsor, the Developer, the General
          Partner, and the Investment Manager. The Alamo Group is founded and
          led by Mr. Logan Anjaneyulu, a successful real estate investor with
          over twenty years of real estate investment and development
          experience.
          <br />
          <br />
          The Developer, Alamo Multifamily Group, is a seasoned real estate
          development firm consisting of a strong suite of development
          professionals with extensive experience in all facets of multifamily
          development. Team members have developed over 10,000 units across
          multiple States, over the last 45 years with a combined multifamily
          development experience. AMG is a vertically integrated development
          company with its primary focus on development of Class-A,
          garden-style, 4&5-Story wrap, and midrise multifamily properties in
          primary and secondary markets throughout the State of TX. AMG's
          development process includes all phases such as site selection, site
          planning, market needs analysis, pre-design, architectural,
          construction, stabilization, and final exit.
          <br />
          <br />
          AMG's current active multifamily pipeline includes development of over
          ~2,200 units at various stages of development, with an estimated 120
          units under construction in 2022 with planned certificate of occupancy
          (CO) on or by December 2023; and ~2,000 units planned for construction
          start in 2024 and 2025 with deliveries through 2026 and 2027.
          <br />
          <br />
          With years of multifamily experience, the development team has
          fostered some exceptional relationships with various players such as
          Architects, Engineers, General Contractors, Property Management
          Companies, Lending Institutions, Institutional Buyers, Land Brokers,
          Limited Partners, General Partners, and Joint Venture Partners to
          excel in projects we develop. With Alamo Group Corporate offices in
          San Antonio, TX; and AMG Offices in Houston, TX; the AMG team is well
          positioned geographically to handle its entire pipeline of projects
          across the State of TX with ease and efficiency. In addition to home
          offices, we maintain project offices at our project sites.
        </p>
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4">
        {/* <Fade bottom> */}
        <div className="cover_image">
          <img src={AMGCover} alt="AMG" />
        </div>
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4" ref={leaderRef}>
        {/* <Fade bottom> */}
        <h4 className="fw-bolder text-app-primary">Leadership Team</h4>
        {/* </Fade> */}
        <hr />
        {/* <Fade bottom> */}
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Logan-Anjaneyulu_nh8nou.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Logan Anjaneyulu <span>Founder, President, & CEO</span>
                </h3>
                <p className="text-justify">
                  Logan Anjaneyulu founded Alamo Equity, an Alamo Group Company
                  in 2017, and AMG shortly thereafter. AMG is a multifamily
                  development company, and its team members have combined, over
                  years of multifamily development experience. Logan Anjaneyulu
                  has over 20 years of real estate investing experience and his
                  expertise include syndication, development, and management of
                  various real estate projects. Logan has over 20 years of
                  corporate, construction, energy, commercial real estate
                  experience, with many successful exits. At the Alamo Group,
                  Logan is responsible for strategy, capital raising, investment
                  screening, and execution. Logan holds a Civil Engineering
                  undergraduate degree from College of Engineering Guindy at
                  Anna University, Chennai, India; a Master of Science (M.S) in
                  Construction Management from Michigan State University, East
                  Lansing, Michigan; and an MBA from Kellogg School of
                  Management at Northwestern University, Evanston, Illinois. As
                  Founder and President, Logan provides mentorship and oversight
                  to the Executive Leadership team at Alamo Group Companies
                  (AGC) globally.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  style={{ background: "#fff" }}
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/pete%20alford.png"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Pete Alford <span>Managing Director, Capital Markets</span>
                </h3>
                <p className="text-justify">
                  Pete has over 35 years of experience in fundraising, capital
                  markets structuring, sales/marketing, investment management
                  consulting, business development, and alternative strategies,
                  working in Toronto, New York, London (UK), Denver, Chicago,
                  Phoenix, and Los Angeles. Pete has held positions as Managing
                  Director and Senior Vice President at some of the world's
                  global financial institutions such as JPMorgan Private Bank,
                  Morgan Stanley, and RBC/City National Bank. He has focused on
                  real estate, private equity and alternative investment
                  management for high net worth, family office and institutional
                  clients. Pete spearheads fundraising and capital needs
                  planning with a focus on the creation, implementation, and
                  execution of multiple fundraising rounds. He leads the
                  Investor Relations and Capital Markets team that focuses on
                  discussions and engagement with HNW/UHNW Investors, Family
                  Offices, Venture Capital Funds, Private Equity Institutions
                  and Pension/Insurance Funds. Community and industry
                  involvement includes Board Member, Care Fund (Phoenix) as well
                  as a member of NMHC and ULI nationally. Pete holds an Honors
                  BA in business administration from Western University (London,
                  Ontario) and an MBA/Finance from the University of Denver,
                  Daniels School of Business.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </Fade> */}
        {/* <Fade bottom> */}
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Larry_Waters_whitebg_y56t2r.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Larry Waters <span>President - Multifamily</span>
                </h3>
                <p className="text-justify">
                  Larry has over 50 years of multifamily construction experience
                  with extensive working knowledge of various facets of
                  multifamily development process from site selection to site
                  planning, entitlements, zoning, utilities, pre-construction,
                  contractor selection, construction, leasing, stabilization,
                  and asset exits - the full lifecycle of multifamily investing.
                  Larry has been directly involved in the construction of over
                  100 apartment projects and 40,000 units across 18 different
                  States. Larry has developed apartments in multiple States: TX,
                  Ohio, Indiana, North and South Carolina, Virginia, Kansas,
                  Oklahoma, Maryland, Delaware, Utah, New Jersey, Pennsylvania,
                  Colorado, Georgia, and Florida. He has built and delivered
                  over $6 billion worth of multifamily projects over 50 years.
                  Larry currently offices in Houston, TX and serves as President
                  of Alamo Multifamily Group, currently overseeing development
                  of over 2,500 units under development and another 6,000 units
                  in its prospective development pipeline.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </Fade> */}
        {/* <Fade bottom> */}
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Kenneth-Steidley-_whitebg_es0gsc.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Kenneth Steidley <span>VP Development, Land Acquisition</span>
                </h3>
                <p className="text-justify">
                  Ken Steidley serves as Vice President of Development and Land
                  Acquisition at AMG. With over 30 years of commercial real
                  estate, Mr. Steidley has sold over 30,000 apartment units
                  valued at over $1.5 billion. Recognized as a leading expert in
                  the commercial real estate field, Mr. Steidley has represented
                  some of the largest multifamily real estate companies in the
                  country, and has been responsible for several multifamily key
                  transactions, including the sale, for one client of more than
                  4,000 apartment units. Mr. Steidley is an active part of the
                  commercial real estate community, being involved in both the
                  Houston Apartment Association and the TX Apartment
                  Association. Community involvement includes membership in the
                  Sugarcreek Country Club and St. Lawrence Catholic Church.
                  Since October 2020, Ken has successfully identified,
                  negotiated, contracted, and bought over 10 sites for AMG for a
                  total of over 150-acres in multiple high performing TX cities.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </Fade> */}
        {/* <Fade bottom> */}
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Mili-Patel-1_dbyhwa.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Mili Patel <span>VP and General Counsel</span>
                </h3>
                <p className="text-justify">
                  Mili Patel joined Alamo Equity as its Vice President and
                  General Counsel in July of 2020. She has been practicing law
                  in the state of TX for 18 years with experience in corporate
                  transactions, real estate, labor law, construction law, and
                  litigation. She earned a Juris Doctorate from TX A&M School of
                  Law and a Bachelor of Business Admin in Economics from Baylor
                  University. Mili is responsible for overseeing all legal
                  matters at the Alamo Group including real estate acquisitions,
                  dispositions, compliance, and general counsel. In addition,
                  Mili is responsible for supervising outside counsel in Alamo
                  Group's various transactions and other legal matters.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://res.cloudinary.com/cloudpencils-media/image/upload/v1681317745/image001_002_qmyr88.png"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Kyle Lavergne
                  <span>Associate, Acquisitions - Investor Relations</span>
                </h3>
                <p className="text-justify">
                  Kyle specializes in acquisitions and underwriting for Alamo.
                  Prior to joining Alamo Kyle specialized in multi-family and
                  student housing acquisitions for high-net-worth individuals
                  and companies. He also privately consulted in underwriting for
                  groups out of New York, Arizona, Florida, TX, and Michigan.
                  Kyle has been tasked with underwriting individual
                  institutional deals valued at $100+ Million. He has also
                  garnered extensive knowledge in the restructuring of
                  struggling portfolios.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4" ref={trackRef}>
        {/* <Fade bottom> */}
        <h4 className="fw-bolder  text-app-primary">Track Record</h4>
        {/* </Fade> */}
        <hr />
        <div className="row">
          <div className="col-md-12 text-center">
            {/* <Fade bottom> */}
            <div className="text-justify mb-4 help-text-track">
              Alamo Multifamily Group (AMG) was formed by Mr. Anjaneyulu after
              years of successful real estate investing. In October 2020, AMG
              onboarded a strong suite of multifamily talent with extensive
              experience of multifamily land acquisition, zoning, vertical
              development, asset management, and asset sale experience. The
              collective knowledge, skill and experience coupled with AMG's
              corporate team form what today is AMG. The project experience
              shown in the table below with excess of 10,000 multifamily units
              represents the overall development and construction experience of
              the combined team. The projects post 2020 represent the direct
              efforts of AMG Team and are fully sponsored by Alamo Group with
              equity funds managed by Alamo Equity.
            </div>
            {/* </Fade> */}

            {/* <Fade bottom> */}
            <div className="table table-responsive mb-5 m-auto">
              <table className="table table-bordered mb-0 table-sm table-track-record">
                <thead>
                  <tr className="theme-bg-table">
                    <th>S.No</th>
                    <th className="text-start" style={{ width: "11.8rem" }}>
                      Multifamily Project Name
                    </th>
                    <th>Year</th>
                    <th style={{ width: "6.2rem" }}>Type</th>
                    <th className="text-start" style={{ width: "8.2rem" }}>
                      City, State
                    </th>
                    <th>Units Built</th>

                    <th style={{ width: "5.2rem" }}>Cost Basis</th>
                    <th style={{ width: "5.2rem" }}>Equity Invested</th>
                    <th style={{ width: "5.2rem" }}>Disposition Price</th>
                    <th style={{ width: "5.2rem" }}>Realized IIR</th>
                    <th style={{ width: "5.2rem" }}>Equity Multiple</th>
                    <th style={{ width: "5.2rem" }}>Hold Period (in years)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="text-start">Parc at 980 *</td>
                    <td>1996</td>
                    <td>Garden Style</td>
                    <td className="text-start">Lawrenceville, GA</td>
                    <td>360</td>
                    <td>$50.7 M</td>
                    <td>$13.2 M</td>
                    <td>$64.6 M</td>
                    <td>32.8%</td>
                    <td>2.5x</td>
                    <td>6.5</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="text-start">Korman - Avenue at Malvern *</td>
                    <td>1997</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">Malvern, PA</td>
                    <td>153</td>
                    <td>$21.7 M</td>
                    <td>$5.9 M</td>
                    <td>$30.4 M</td>
                    <td>33.5%</td>
                    <td>2.3x</td>
                    <td>4.6</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="text-start">
                      Mallard Crossing Apartments *
                    </td>
                    <td>1997</td>
                    <td>Garden Style</td>
                    <td className="text-start">Loveland, OH</td>
                    <td>350</td>
                    <td>$50.4 M</td>
                    <td>$15.1 M</td>
                    <td>$74.9 M</td>
                    <td>38.2%</td>
                    <td>2.4x</td>
                    <td>6.8</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td className="text-start">Brandywine Woods *</td>
                    <td>1998</td>
                    <td>Garden Style</td>
                    <td className="text-start">Bear, DE</td>
                    <td>265</td>
                    <td>$38.4 M</td>
                    <td>$10.8 M</td>
                    <td>$48.5 M</td>
                    <td>30.4%</td>
                    <td>2.2x</td>
                    <td>6.3</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td className="text-start">River Crossing *</td>
                    <td>1999</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">Indianapolis, IN</td>
                    <td>314</td>
                    <td>$45.7 M</td>
                    <td>$13.3 M</td>
                    <td>$56.6 M</td>
                    <td>36.9%</td>
                    <td>2.3x</td>
                    <td>6.2</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td className="text-start">Tradition at Mallard Creek *</td>
                    <td>1999</td>
                    <td>Garden Style</td>
                    <td className="text-start">Charlotte, NC</td>
                    <td>360</td>
                    <td>$53.3 M</td>
                    <td>$13.9 M</td>
                    <td>$65.8 M</td>
                    <td>24.9%</td>
                    <td>2.4x</td>
                    <td>7.2</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td className="text-start">
                      Korman - Avenue at Somerset *
                    </td>
                    <td>2001</td>
                    <td>Garden Style</td>
                    <td className="text-start">Somerset, NJ</td>
                    <td>301</td>
                    <td>$45.2 M</td>
                    <td>$13.1 M</td>
                    <td>$66.2 M</td>
                    <td>27.8%</td>
                    <td>2.6x</td>
                    <td>6.0</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td className="text-start">
                      Vineyards of Colorado Springs *
                    </td>
                    <td>2002</td>
                    <td>Garden Style</td>
                    <td className="text-start">Colorado Springs, CO</td>
                    <td>304</td>
                    <td>$46.3 M</td>
                    <td>$12.0 M</td>
                    <td>$66.7 M</td>
                    <td>24.5%</td>
                    <td>2.4x</td>
                    <td>5.6</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td className="text-start">Siena Apartments *</td>
                    <td>2003</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">Houston, TX</td>
                    <td>224</td>
                    <td>$34.5 M</td>
                    <td>$10.4 M</td>
                    <td>$44.2 M</td>
                    <td>24.4%</td>
                    <td>2.3x</td>
                    <td>4.2</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td className="text-start">Stone Point Apartments *</td>
                    <td>2005</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">Annapolis, MD</td>
                    <td>312</td>
                    <td>$48.6 M</td>
                    <td>$12.6 M</td>
                    <td>$59.1 M</td>
                    <td>27.6%</td>
                    <td>2.3x</td>
                    <td>6.7</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td className="text-start">Cypress Legends *</td>
                    <td>2006</td>
                    <td>Garden Style</td>
                    <td className="text-start">Fort Myers, FL</td>
                    <td>332</td>
                    <td>$52.2 M</td>
                    <td>$13.1 M</td>
                    <td>$72.6 M</td>
                    <td>29.9%</td>
                    <td>2.3x</td>
                    <td>7.3</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td className="text-start">Ingleside Plantation *</td>
                    <td>2007</td>
                    <td>Garden Style</td>
                    <td className="text-start">Charleston, SC</td>
                    <td>304</td>
                    <td>$48.4 M</td>
                    <td>$12.1 M</td>
                    <td>$72.1 M</td>
                    <td>38.1%</td>
                    <td>2.6x</td>
                    <td>6.3</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td className="text-start">Pinnacle Ridge Apartments *</td>
                    <td>2007</td>
                    <td>Garden Style</td>
                    <td className="text-start">Dallas, TX</td>
                    <td>296</td>
                    <td>$47.6 M</td>
                    <td>$11.9 M</td>
                    <td>$68.4 M</td>
                    <td>28.4%</td>
                    <td>2.3x</td>
                    <td>6.1</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td className="text-start">Park Hudson Apartments *</td>
                    <td>2007</td>
                    <td>Garden Style</td>
                    <td className="text-start">College Station, TX</td>
                    <td>232</td>
                    <td>$37.7 M</td>
                    <td>$11.3 M</td>
                    <td>$52.8 M</td>
                    <td>39.9%</td>
                    <td>2.6x</td>
                    <td>5.5</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td className="text-start">Bay Vista Apartments *</td>
                    <td>2008</td>
                    <td>Wrap Style</td>
                    <td className="text-start">Corpus Christi, TX</td>
                    <td>169</td>
                    <td>$27.8 M</td>
                    <td>$8.3 M</td>
                    <td>$33.8 M</td>
                    <td>30.7%</td>
                    <td>2.3x</td>
                    <td>4.3</td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td className="text-start">Ingleside Plantation *</td>
                    <td>2008</td>
                    <td>Garden Style</td>
                    <td className="text-start">Charleston, SC</td>
                    <td>304</td>
                    <td>$50.7 M</td>
                    <td>$15.2 M</td>
                    <td>$72.6 M</td>
                    <td>24.0%</td>
                    <td>2.2x</td>
                    <td>7.3</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td className="text-start">Hunter's Cove *</td>
                    <td>2008</td>
                    <td>Garden Style</td>
                    <td className="text-start">Waxahacie, TX</td>
                    <td>192</td>
                    <td>$32.4 M</td>
                    <td>$9.1 M</td>
                    <td>$44.9 M</td>
                    <td>37.1%</td>
                    <td>2.2x</td>
                    <td>3.7</td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td className="text-start">Villa Lago Apartments *</td>
                    <td>2008</td>
                    <td>Garden Style</td>
                    <td className="text-start">Ft. Worth, TX</td>
                    <td>204</td>
                    <td>$34.8 M</td>
                    <td>$9.4 M</td>
                    <td>$43.5 M</td>
                    <td>35.8%</td>
                    <td>2.6x</td>
                    <td>4.8</td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td className="text-start">Vance Jackson Legends *</td>
                    <td>2009</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>400</td>
                    <td>$69.2 M</td>
                    <td>$18.7 M</td>
                    <td>$101.9 M</td>
                    <td>31.5%</td>
                    <td>2.6x</td>
                    <td>7.4</td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td className="text-start">Encore Crossing Apartments *</td>
                    <td>2009</td>
                    <td>Garden Style</td>
                    <td className="text-start">Corpus Christi, TX</td>
                    <td>234</td>
                    <td>$41.2 M</td>
                    <td>$12.4 M</td>
                    <td>$58.9 M</td>
                    <td>32.0%</td>
                    <td>2.3x</td>
                    <td>5.2</td>
                  </tr>
                  <tr>
                    <td>21</td>
                    <td className="text-start">Boulder Creek *</td>
                    <td>2010</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>400</td>
                    <td>$70.8 M</td>
                    <td>$18.4 M</td>
                    <td>$91.9 M</td>
                    <td>32.3%</td>
                    <td>2.2x</td>
                    <td>7.1</td>
                  </tr>
                  <tr>
                    <td>22</td>
                    <td className="text-start">Belle Forte Apartments *</td>
                    <td>2011</td>
                    <td>Garden Style</td>
                    <td className="text-start">Beaumont, TX</td>
                    <td>360</td>
                    <td>$64.7 M</td>
                    <td>$19.4 M</td>
                    <td>$84.5 M</td>
                    <td>28.0%</td>
                    <td>2.6x</td>
                    <td>6.9</td>
                  </tr>
                  <tr>
                    <td>23</td>
                    <td className="text-start">The Plantation I *</td>
                    <td>2011</td>
                    <td>Garden Style</td>
                    <td className="text-start">Mission, TX</td>
                    <td>150</td>
                    <td>$27.3 M</td>
                    <td>$7.1 M</td>
                    <td>$36.8 M</td>
                    <td>24.7%</td>
                    <td>2.2x</td>
                    <td>3.8</td>
                  </tr>
                  <tr>
                    <td>24</td>
                    <td className="text-start">
                      Bay Vista Pointe Apartments *
                    </td>
                    <td>2013</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">Corpus Christi, TX</td>
                    <td>169</td>
                    <td>$31.2 M</td>
                    <td>$9.0 M</td>
                    <td>$42.2 M</td>
                    <td>27.5%</td>
                    <td>2.5x</td>
                    <td>3.5</td>
                  </tr>
                  <tr>
                    <td>25</td>
                    <td className="text-start">West Oak Apartments *</td>
                    <td>2013</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>352</td>
                    <td>$65.6 M</td>
                    <td>$17.1 M</td>
                    <td>$90.9 M</td>
                    <td>37.4%</td>
                    <td>2.3x</td>
                    <td>7.5</td>
                  </tr>
                  <tr>
                    <td>26</td>
                    <td className="text-start">The Plantation II *</td>
                    <td>2013</td>
                    <td>Garden Style</td>
                    <td className="text-start">Mission, TX</td>
                    <td>150</td>
                    <td>$28.3 M</td>
                    <td>$8.5 M</td>
                    <td>$34.1 M</td>
                    <td>38.1%</td>
                    <td>2.4x</td>
                    <td>4.5</td>
                  </tr>
                  <tr>
                    <td>27</td>
                    <td className="text-start">Parkway Fall Apartments *</td>
                    <td>2014</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Marcos, TX</td>
                    <td>192</td>
                    <td>$36.7 M</td>
                    <td>$10.6 M</td>
                    <td>$48.6 M</td>
                    <td>28.4%</td>
                    <td>2.3x</td>
                    <td>5.3</td>
                  </tr>
                  <tr>
                    <td>28</td>
                    <td className="text-start">Oak Forest Apartments *</td>
                    <td>2015</td>
                    <td>Garden Style</td>
                    <td className="text-start">Victoria, TX</td>
                    <td>264</td>
                    <td>$51.1 M</td>
                    <td>$14.3 M</td>
                    <td>$70.6 M</td>
                    <td>28.1%</td>
                    <td>2.6x</td>
                    <td>7.5</td>
                  </tr>
                  <tr>
                    <td>29</td>
                    <td className="text-start">Oak Tree Apartments *</td>
                    <td>2015</td>
                    <td>Garden Style</td>
                    <td className="text-start">Kingsville, TX</td>
                    <td>192</td>
                    <td>$37.7 M</td>
                    <td>$10.6 M</td>
                    <td>$53.6 M</td>
                    <td>36.9%</td>
                    <td>2.6x</td>
                    <td>6.1</td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td className="text-start">Forest Grove Apartments *</td>
                    <td>2019</td>
                    <td>Garden Style</td>
                    <td className="text-start">College Station, TX</td>
                    <td>84</td>
                    <td>$16.7 M</td>
                    <td>$5.0 M</td>
                    <td>$21.8 M</td>
                    <td>30.5%</td>
                    <td>2.2x</td>
                    <td>4.2</td>
                  </tr>
                  <tr>
                    <td>31</td>
                    <td className="text-start">Forest Pines Apartments *</td>
                    <td>2019</td>
                    <td>Garden Style</td>
                    <td className="text-start">College Station, TX</td>
                    <td>240</td>
                    <td>$48.3 M</td>
                    <td>$12.1 M</td>
                    <td>$68.3 M</td>
                    <td>33.7%</td>
                    <td>2.4x</td>
                    <td>5.3</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="theme-bg-table">
                    <th colSpan={5}>
                      Projects Developed by Alamo Multifamily Group (AMG) Team
                      Members *
                    </th>

                    <th>8,163</th>
                    <th>$1,335.2 M</th>
                    <th>$373.9 M</th>
                    <th>$1,841.8 M</th>
                    <th>31%</th>
                    <th>2.4x</th>
                    <th>5.8</th>
                  </tr>
                </tfoot>
              </table>
            </div>

            <h5 className="text-app-primary text-start">
              Alamo Equity & Affiliates: Exits in Last 5 Years
            </h5>

            <div className="table table-responsive mb-5 m-auto">
              <table className="table table-bordered mb-0 table-sm table-track-record">
                <thead>
                  <tr className="theme-bg-table">
                    <th>S.No</th>
                    <th className="text-start" style={{ width: "11.8rem" }}>
                      CRE Project Name
                    </th>
                    <th>Year</th>
                    <th style={{ width: "6.2rem" }}>Type</th>
                    <th className="text-start" style={{ width: "3rem" }}>
                      City, State
                    </th>
                    <th className="text-start">Description</th>

                    <th style={{ width: "5.2rem" }}>Cost Basis</th>
                    <th style={{ width: "5.2rem" }}>Equity Invested</th>
                    <th style={{ width: "5.2rem" }}>Exit Value</th>
                    <th style={{ width: "5.2rem" }}>Realized IIR</th>
                    <th style={{ width: "5.2rem" }}>Equity Multiple</th>
                    <th style={{ width: "5.2rem" }}>Hold Period (in years)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="text-start">Marriott Autograph Project</td>
                    <td>2018</td>
                    <td>Hotel</td>
                    <td className="text-start">McKinney, TX</td>
                    <td className="text-start">Fully Developed & Entitled</td>
                    <td>$5.2 M</td>
                    <td>$4.2 M</td>
                    <td>$12.0 M</td>
                    <td>31%</td>
                    <td>2.2x</td>
                    <td>2.3</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="text-start">Craig Ranch Corporate Center</td>
                    <td>2018</td>
                    <td>Commercial</td>
                    <td className="text-start">McKinney, TX</td>
                    <td className="text-start">Fully Developed & Entitled</td>
                    <td>$7.2 M </td>
                    <td>$3.5 M </td>
                    <td>$8.4 M </td>
                    <td>18%</td>
                    <td>1.6x</td>
                    <td>2.0</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="text-start">New Braunfels, TX</td>
                    <td>2022</td>
                    <td>Multifamily</td>
                    <td className="text-start">New Braunfels, TX</td>
                    <td className="text-start">Fully Entitled Multifamily</td>
                    <td>$5.3 M </td>
                    <td>$3.2 M </td>
                    <td>$13.2 M </td>
                    <td>57%</td>
                    <td>4.8x</td>
                    <td>0.3</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td className="text-start">Forest Grove Apartments</td>
                    <td>2019</td>
                    <td>Multifamily</td>
                    <td className="text-start">College Station, TX</td>
                    <td className="text-start">Garden Style Multifamily</td>
                    <td>$21.8 M </td>
                    <td>$12.3 M </td>
                    <td>$68.3 M </td>
                    <td>29%</td>
                    <td>3.1x</td>
                    <td>3.0</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td className="text-start">3 Trailer Park Investments</td>
                    <td>2020</td>
                    <td>Trailer Parks</td>
                    <td className="text-start">Lake Texoma, TX</td>
                    <td className="text-start">Trailer Parks and RV Parks</td>
                    <td>$32.5 M </td>
                    <td>$25.0 M </td>
                    <td>$65.0 M </td>
                    <td>26%</td>
                    <td>2.6x</td>
                    <td>3.4</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td className="text-start">
                      Craig Ranch Estate Lots/TPC GC
                    </td>
                    <td>2021</td>
                    <td>Retail</td>
                    <td className="text-start">McKinney, TX</td>
                    <td className="text-start">Retail Sites</td>
                    <td>$18.0 M </td>
                    <td>$12.0 M </td>
                    <td>$25.0 M </td>
                    <td>24%</td>
                    <td>2.2x</td>
                    <td>2.5</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="theme-bg-table">
                    <th colSpan={6}>
                      Total Alamo Equity & Affiliates: Exits in Last 5 Years
                    </th>

                    <th>$90.0 M</th>
                    <th>$60.2 M</th>
                    <th>$191.9 M</th>
                    <th>31%</th>
                    <th>2.75x</th>
                    <th>2.2</th>
                  </tr>
                </tfoot>
              </table>
            </div>

            <h5 className="text-app-primary text-start">
              Alamo Equity AUM: Current Projects
            </h5>

            <div className="table table-responsive mb-5 m-auto">
              <table className="table table-bordered mb-0 table-sm table-track-record">
                <thead>
                  <tr className="theme-bg-table">
                    <th rowSpan={2}>S.No</th>
                    <th
                      rowSpan={2}
                      className="text-start"
                      style={{ width: "11.8rem" }}
                    >
                      CRE Project Name
                    </th>
                    <th rowSpan={2}>Year</th>
                    <th rowSpan={2} style={{ width: "6.2rem" }}>
                      Type
                    </th>
                    <th
                      rowSpan={2}
                      className="text-start"
                      style={{ width: "3rem" }}
                    >
                      City, State
                    </th>
                    <th rowSpan={2}>Target Units</th>
                    <th style={{ width: "5.2rem" }} colSpan={2}>
                      Total Cost Basis
                    </th>
                    <th style={{ width: "5.2rem" }} colSpan={2}>
                      Total Equity
                    </th>
                    <th rowSpan={2} style={{ width: "15rem" }}>
                      Target Exit Value
                    </th>
                    <th rowSpan={2} style={{ width: "5.2rem" }}>
                      Realized IIR
                    </th>
                    <th rowSpan={2} style={{ width: "5.2rem" }}>
                      Equity Multiple
                    </th>
                    <th rowSpan={2} style={{ width: "5.2rem" }}>
                      Hold Period (in years)
                    </th>
                  </tr>
                  <tr className="theme-bg-table">
                    <th style={{ width: "6rem" }}>Current</th>
                    <th>At Completion</th>
                    <th style={{ width: "6rem" }}>Invested</th>
                    <th>To Be Invested</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="text-start">Spurs Lane Apartments</td>
                    <td>2022</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>120</td>
                    <td>$25.4 M </td>
                    <td>$25.0 M </td>
                    <td>$4.2 M </td>
                    <td>$0.0 </td>
                    <td>$42.0 M </td>
                    <td>32%</td>
                    <td>4.0x</td>
                    <td>3.0</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="text-start">Craig Ranch Corporate Center</td>
                    <td>2020</td>
                    <td>Commercial</td>
                    <td className="text-start">McKinney, TX</td>
                    <td>Corporate</td>
                    <td>$20.8 M </td>
                    <td>$25.0 M </td>
                    <td>$12.0 M </td>
                    <td>$2.0 M </td>
                    <td>$60.0 M </td>
                    <td>30%</td>
                    <td>3.0x</td>
                    <td>3.0</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="text-start">
                      El Tropicana Hilton Tapestry Hotel
                    </td>
                    <td>2020</td>
                    <td>Hotel</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>Hotel</td>
                    <td>$38.0 M </td>
                    <td>$68.0 M </td>
                    <td>$18.0 M </td>
                    <td>$20.0 M </td>
                    <td>$90.0 M </td>
                    <td>21%</td>
                    <td>1.9x</td>
                    <td>7.0</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td className="text-start">
                      Single-Family Land Development
                    </td>
                    <td>2021</td>
                    <td>Land</td>
                    <td className="text-start">Georgetown, TX</td>
                    <td>238 Acres</td>
                    <td>$20.0 M </td>
                    <td>$28.0 M </td>
                    <td>$8.0 M </td>
                    <td>$5.0 M </td>
                    <td>$40.0 M </td>
                    <td>42%</td>
                    <td>3.2x</td>
                    <td>2.1</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td className="text-start">Alma 121 Land Investment</td>
                    <td>2023</td>
                    <td>Land</td>
                    <td className="text-start">Allen, TX</td>
                    <td>80 Acres - Land</td>
                    <td>$32.0 M </td>
                    <td>$50.0 M </td>
                    <td>$20.0 M </td>
                    <td>$5.0 M </td>
                    <td>$70.0 M </td>
                    <td>20%</td>
                    <td>1.9x</td>
                    <td>7.0</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td className="text-start">Stone Oak Apartments</td>
                    <td>2023</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>264</td>
                    <td>$10.0 M </td>
                    <td>$65.0 M </td>
                    <td>$10.0 M </td>
                    <td>$15.0 M </td>
                    <td>$100.0 M </td>
                    <td>22%</td>
                    <td>2.0x</td>
                    <td>6.0</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td className="text-start">Afton Oak Apartments</td>
                    <td>2023</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>478</td>
                    <td>$15.0 M </td>
                    <td>$110.0 M </td>
                    <td>$15.0 M </td>
                    <td>$30.0 M </td>
                    <td>$180.0 M </td>
                    <td>26%</td>
                    <td>2.1x</td>
                    <td>6.0</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td className="text-start">Afton Oak Retail Development</td>
                    <td>2023</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>478</td>
                    <td>$5.0 M </td>
                    <td>$40.0 M </td>
                    <td>$5.0 M </td>
                    <td>$15.0 M </td>
                    <td>$60.0 M </td>
                    <td>28%</td>
                    <td>2.3x</td>
                    <td>5.0</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td className="text-start">College Station Apartments</td>
                    <td>2023</td>
                    <td>Garden Style</td>
                    <td className="text-start">College Station, TX</td>
                    <td>336</td>
                    <td>$6.0 M </td>
                    <td>$75.0 M </td>
                    <td>$6.0 M </td>
                    <td>$30.0 M </td>
                    <td>$110.0 M </td>
                    <td>24%</td>
                    <td>2.2x</td>
                    <td>5.0</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="theme-bg-table">
                    <th colSpan={6}>
                      Total Alamo Equity AUM: Current Projects
                    </th>

                    <th>$172 M</th>
                    <th>$780 M</th>
                    <th>$98 M</th>
                    <th>$216 M</th>
                    <th>$752 M</th>
                    <th>27%</th>
                    <th>2.5x</th>
                    <th>4.9</th>
                  </tr>
                </tfoot>
              </table>
            </div>

            <h5 className="text-app-primary text-start">
              Alamo Equity: Current Cash Flowing Building Assets
            </h5>

            <div className="table table-responsive mb-5 m-auto">
              <table className="table table-bordered mb-0 table-sm table-track-record">
                <thead>
                  <tr className="theme-bg-table">
                    <th style={{ width: "1rem" }}>S.No</th>
                    <th className="text-start">CRE Project Name</th>
                    <th style={{ width: "3rem" }}>Year</th>
                    <th style={{ width: "6.2rem" }}>Type</th>
                    <th className="text-start" style={{ width: "7rem" }}>
                      City, State
                    </th>
                    <th>Asset Value</th>
                    <th>Total Cost Basis</th>
                    <th>Total Equity Invested</th>
                    <th>Target Exit</th>
                    <th>Target IIR</th>
                    <th>Equity Multiple</th>
                    <th>Hold Period(in years)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="text-start">2030 Office Building</td>
                    <td>2020</td>
                    <td>Office</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>$8.0 M </td>
                    <td>$6.4 M</td>
                    <td>$1.0 M</td>
                    <td>$8.0 M</td>
                    <td>24%</td>
                    <td>2.20x</td>
                    <td>4.5</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="text-start">4725 College Park Building</td>
                    <td>2020</td>
                    <td>Office</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>$3.5 M </td>
                    <td>$2.8 M</td>
                    <td>$0.7 M</td>
                    <td>$3.5 M</td>
                    <td>22%</td>
                    <td>1.90x</td>
                    <td>4.0</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="text-start">3503 Paesano&#39;s Building</td>
                    <td>2019</td>
                    <td>Office</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>$7.8 M </td>
                    <td>$4.8 M</td>
                    <td>$1.2 M</td>
                    <td>$8.0 M</td>
                    <td>28%</td>
                    <td>2.30x</td>
                    <td>2.0</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td className="text-start">1102 Sonterra Building</td>
                    <td>2019</td>
                    <td>Office</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>$7.0 M </td>
                    <td>$5.6 M</td>
                    <td>$2.0 M</td>
                    <td>$7.5 M</td>
                    <td>23%</td>
                    <td>1.80x</td>
                    <td>5.0</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td className="text-start">
                      2026 Office Lot (Under) Development
                    </td>
                    <td>2023</td>
                    <td>Office</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>$6.0 M </td>
                    <td>$3.5 M</td>
                    <td>$1.0 M</td>
                    <td>$7.0 M</td>
                    <td>24%</td>
                    <td>2.40x</td>
                    <td>5.0</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td className="text-start">
                      2 Spurs Lane Medical Building
                    </td>
                    <td>2020</td>
                    <td>Medical Office</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>$3.0 M </td>
                    <td>$2.6 M</td>
                    <td>$0.7 M</td>
                    <td>$3.2 M</td>
                    <td>26%</td>
                    <td>1.85x</td>
                    <td>4.0</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="theme-bg-table">
                    <th colSpan={5}>
                      Total Alamo Equity: Current Cash Flowing Building Assets
                    </th>
                    <th>$35.3 M</th>
                    <th>$35.3 M</th>
                    <th>$6.5 M</th>
                    <th>$37.2 M</th>
                    <th>25%</th>
                    <th>2.08x</th>
                    <th>4.1</th>
                  </tr>
                </tfoot>
              </table>
            </div>

            <h5 className="text-app-primary text-start">
              Under Contract Sites/Assets
            </h5>

            <div className="table table-responsive mb-5 m-auto">
              <table className="table table-bordered mb-0 table-sm table-track-record">
                <thead>
                  <tr className="theme-bg-table">
                    <th style={{ width: "1rem" }}>S.No</th>
                    <th className="text-start" style={{ width: "11.8rem" }}>
                      CRE Project Name
                    </th>
                    <th style={{ width: "3rem" }}>Year</th>
                    <th style={{ width: "6.2rem" }}>Type</th>
                    <th className="text-start" style={{ width: "3rem" }}>
                      City, State
                    </th>
                    <th>Unit Target</th>
                    <th>Phase</th>
                    <th>Location Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="text-start">Multifamily - Taylor, TX</td>
                    <td>2024</td>
                    <td>Multifamily</td>
                    <td className="text-start">Taylor, TX</td>
                    <td>422</td>
                    <td>34 Acres Under Contract</td>
                    <td>Next to SamsungPlant</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="text-start">Multifamily - Beaumont, TX</td>
                    <td>2024</td>
                    <td>Multifamily</td>
                    <td className="text-start">Beaumont, TX</td>
                    <td>360</td>
                    <td>20 Acres Under Contract</td>
                    <td>Outside Houston MSA</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="text-start">Multifamily - Richmond, TX</td>
                    <td>2024</td>
                    <td>Multifamily</td>
                    <td className="text-start">Richmond, TX</td>
                    <td>360</td>
                    <td>14.5 Acres Under Contract</td>
                    <td>Outside Houston MSA</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="theme-bg-table">
                    <th colSpan={5}>Total Sites/Assets Under Contract</th>
                    <th>1142</th> <th>68.5 Acres Under Contract</th>
                    <th>Multiple</th>
                  </tr>
                </tfoot>
              </table>
            </div>

            {/* 
            <h5 className="text-app-primary text-start">
              Our Current Projects
            </h5>
            <div className="table table-responsive mb-5 m-auto">
              <table className="table table-bordered mb-0 table-sm table-track-record">
                <thead>
                  <tr className="theme-bg-table">
                    <th>S.No</th>
                    <th className="text-start" style={{ width: "11.8rem" }}>
                      Multifamily Project Name
                    </th>
                    <th>Year</th>
                    <th style={{ width: "6.2rem" }}>Type</th>
                    <th className="text-start" style={{ width: "8.2rem" }}>
                      City, State
                    </th>
                    <th>Target Unit Development</th>

                    <th style={{ width: "5.2rem" }}>Total Cost Basis</th>
                    <th style={{ width: "5.2rem" }}>Target Equity Raise</th>
                    <th style={{ width: "5.2rem" }}>Target Exit Price</th>
                    <th style={{ width: "5.2rem" }}>Target IIR</th>
                    <th style={{ width: "5.2rem" }}>Target Equity Multiple</th>
                    <th style={{ width: "5.2rem" }}>Target Hold Period</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="text-start">Spurs Lane Apartments **</td>
                    <td>2022</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>120</td>
                    <td>$25.6 M</td>
                    <td>$4.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="text-start">Afton Oak Apartments ***</td>
                    <td>2023</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>478</td>
                    <td>$120.0 M</td>
                    <td>$35.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="text-start">Stone Oak Apartments ***</td>
                    <td>2023</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>264</td>
                    <td>$65.0 M</td>
                    <td>$20.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td className="text-start">Bryan/College Station ***</td>
                    <td>2023</td>
                    <td>Garden Style</td>
                    <td className="text-start">College Station, TX</td>
                    <td>336</td>
                    <td>$75.0 M</td>
                    <td>$25.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>5</td>
                    <td className="text-start">Baytown Apartments ***</td>
                    <td>2024</td>
                    <td>Garden Style</td>
                    <td className="text-start">Baytown, TX</td>
                    <td>360</td>
                    <td>$80.0 M</td>
                    <td>$25.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td className="text-start">Taylor Creek Apartments ***</td>
                    <td>2024</td>
                    <td>BTR Product</td>
                    <td className="text-start">Taylor, TX</td>
                    <td>422</td>
                    <td>$105.0 M</td>
                    <td>$30.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td className="text-start">Richmond Apartments ***</td>
                    <td>2023</td>
                    <td>Garden Style</td>
                    <td className="text-start">Richmond, TX</td>
                    <td>360</td>
                    <td>$80.0 M</td>
                    <td>$55.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="theme-bg-table">
                    <th colSpan={5}>
                      Our Current Projects Under Development (2022 - 2024)
                    </th>

                    <th>2,340</th>
                    <th>$550.6 M</th>
                    <th>$194.0 M</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div> */}
            {/* </Fade> */}
            {/* <img
                src="https://res.cloudinary.com/cloudpencils-media/image/upload/v1672798616/funding/production/Track_Record_Image_tbketf.jpg"
                className="w-75  mb-4"
              /> */}
          </div>

          {/* <Fade bottom> */}
          <div className="col-md-12">
            <div className="row ">
              <div className="col-md-6">
                <img
                  src="https://ik.imagekit.io/alamogroup/dev/First_Image_m8qzwq.jpg"
                  className="w-100 mb-1 custom-height-fund-img"
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span>Belle Forte Apartments - Beaumont, TX - 2011 *</span>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src="https://ik.imagekit.io/alamogroup/dev/West_Oak_2_vlhscg.jpg"
                  className="w-100 mb-1 custom-height-fund-img"
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span>
                    West Oak Luxury Apartments - San Antonio, TX - 2013 *
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <img
                  src="https://ik.imagekit.io/alamogroup/dev/oak-forest-aerial-04_evyhnh.jpg"
                  className="w-100 mb-1 custom-height-fund-img"
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span>Oak Forest Apartments - Victoria, TX - 2015 *</span>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src="https://ik.imagekit.io/alamogroup/dev/forest-pines-apartments-bryan-tx-primary-photo_oqo4s8.jpg"
                  className="w-100 mb-1 custom-height-fund-img"
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span>
                    Forest Pine Apartments - College Station, TX - 2019 *
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* </Fade> */}
          {/* <Fade bottom> */}
          <div className="text-justify help-text-track">
            <ul>
              <li>
                Track Record <b className="app-color">Note 1* </b> Projects 1-31
                above are projects developed prior to 2020 and were developed by
                AMG team members prior to joining AMG Team on October 1, 2020.
              </li>
              <li>
                Track Record <b className="app-color">Note 2** </b> Project 32
                above is Spurs Lane Apartment Project that is under construction
                with expected Certificate of Occupancy (CO) on or around
                September 30, 2023.
              </li>
              <li>
                Track Record <b className="app-color">Note 3*** </b> Projects
                33-38 above are projects currently either in feasibility or
                pre-development or development phase with anticipated
                construction starts in 2023 & 2024.
              </li>
            </ul>
          </div>
          {/* </Fade> */}
        </div>
      </div>
      <div className="sp_sec mb-4">
        {/* <Fade bottom> */}
        <h4 className="fw-bolder text-app-primary">Past Projects*</h4>
        {/* </Fade> */}
        <hr />
        <Tabs defaultActiveKey="oak" className="custom_tab3">
          <Tab eventKey="oak" title="Oak Forest">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  ``
                  <Carousel.Item>
                    <img src={oakforest_1} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={oakforest_2} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={oakforest_3} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={oakforest_4} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={oakforest_5} alt="Alamo" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          5312 N. John Stockbauer, Victoria, TX 78249
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>
                          264 Units
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Rentable Area:</b>
                          219,516 sq.ft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          28.40 Acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Completed:</b>
                          2015
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Completed
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Style:</b>
                          Garden Style
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Exterior:</b>
                          Stone and Hardi-Plank Siding
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Roof:</b>
                          Pitched Composition Shingles
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Flooring:</b>
                          Carpet, Wood Style Floors
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>
                          11 3-Story Building + Clubhouse
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Parking:</b>
                          470
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="pines" title="Forest Pines">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img src={Forest_Pines_1} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Forest_Pines_2} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Forest_Pines_3} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Forest_Pines_4} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Forest_Pines_5} alt="Alamo" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          852 N Harvey Mitchell, Bryan, TX 77807
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>
                          328 Units
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Rentable Area:</b>
                          280,440 sq.ft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          18.50 Acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Completed:</b>
                          2020
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          REIT Owned
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Style:</b>
                          Garden Style
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Exterior:</b>
                          Stone and Hardi-Plank Siding
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Roof:</b>
                          Pitched Composition Shingles
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Flooring:</b>
                          Carpet, Laminated & Vinyl Tile
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>
                          14 3-Story Building + Clubhouse
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Parking:</b>
                          574
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="parkway" title="Parkway Falls">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img src={Parkway_Falls_1} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Parkway_Falls_2} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Parkway_Falls_3} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Parkway_Falls_4} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Parkway_Falls_5} alt="Alamo" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          1811 Medical Pkwy., San Marcos, TX 78666
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>
                          192 Units
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Rentable Area:</b>
                          159,936 sq.ft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          10.14 Acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Completed:</b>
                          2014
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Sold
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Style:</b>
                          Garden Style
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Exterior:</b>
                          Stone and Hardi-Plank Siding
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Roof:</b>
                          Pitched Composition Shingles
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Flooring:</b>
                          Carpet, Laminated & Vinyl Tile
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>8 3-Story Building + Clubhouse
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Parking:</b>
                          357
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="west" title="West  Oak">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img src={west_oaks_1} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={west_oaks_2} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={west_oaks_3} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={west_oaks_4} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={west_oaks_5} alt="Alamo" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          14838 Vance Jackson Rd., San Antonio TX 78249
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>
                          352 Units
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Rentable Area:</b>
                          272,756 sq.ft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          16.00 Acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Completed:</b>
                          2013
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Sold
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Style:</b>
                          Garden Style
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Exterior:</b>
                          Stone and Hardi-Plank Siding
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Roof:</b>
                          Pitched Composition Shingles
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Flooring:</b>
                          Carpet, Laminated & Vinyl Tile
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>
                          15 3-Story Building + Clubhouse
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Parking:</b>
                          704
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="bay" title="Bay Vista">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img src={bay_vista_1} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={bay_vista_2} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={bay_vista_3} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={bay_vista_4} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={bay_vista_5} alt="Alamo" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          522 Hancock Avenue and 802 Carancahua, Corpus Christi,
                          TX 78404
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>
                          169 + 164 = 333 Units
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Rentable Area:</b>
                          280,720 sq.ft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          2008 & 2014
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Completed:</b>
                          2008 & 2014
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Sold
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Style:</b>
                          4-style “Wrap”
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Exterior:</b>
                          Brick and Hardi-Plank Siding
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Roof:</b>
                          Pitched Composition Shingles
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Flooring:</b>
                          Carpet, Laminated & Vinyl Tile
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>
                          350 + 290 Parking Spaces | 4-Story Parking Structure
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
        {/* <Tabs defaultActiveKey="leader" className="mb-3 custom-css-tabs" fill>
          <Tab eventKey="leader" title="Leadership Team"></Tab>
          <Tab eventKey="track" title="Track Record"></Tab>
          <Tab eventKey="oak" title="Past Projects"></Tab>
        </Tabs> */}
      </div>
    </>
  );
};

export default People;
