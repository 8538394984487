import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  login: false,
  loading: false,
  error: false,
  errorData: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login(state, { payload }) {
      return { ...state, login: true, data: payload };
    },
    logout() {
      return initialState;
    },
  },
});

export const { login, logout } = userSlice.actions;

export const sampleSelector = (state) => state.user?.sampleData;

export default userSlice.reducer;
